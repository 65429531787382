@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800;900&display=swap");

body,
html {
  font-family: "Inter", sans-serif;
}

p {
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: center;
  line-height: 185%;
  display: initial;
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
}

a {
  font-size: 14px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: center;
  color: #6c5ce7;
  text-decoration: none;
}

/* common properties for app component content*/

/* 
not used in app config field , Sidebar
*/
.app-component-content {
  padding: 20px;
  text-align: center;
}

.app-component-content > p {
  padding: 20px;
}

/* asset sidebar styles */

/* dashboard styles */

.dashboard {
  box-sizing: border-box;
  display: flex;
  width: auto;
  height: 290px;
  justify-content: center;
  align-items: center;
  border: 1px dashed black;
  overflow: hidden;
  border-radius: 5px;
}

.dashboard-container * {
  margin: 0%;
  padding: 0%;
}

.dashboard-container .app-component-content > * {
  margin: 0%;
  padding: 15px;
  text-decoration: double;
}

.dashboard-icon {
  display: flex;
  justify-content: center;
}

.configValues {
  border: 1px dashed rgb(188, 187, 187);
  padding: 5px !important;
  border-radius: 5px;
  display: inline-flex;
  width: fit-content;
}

/* JSONRte Extension */

.jsonrte-extension {
  padding: 0.5rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.jsonrte-extension > label {
  font-weight: 700;
}

.jsonrte-extension > p {
  padding: 0.25rem;
  color: #000000;
  border-bottom-right-radius: 0.375rem;
  border-bottom-left-radius: 0.375rem;
  border-bottom-width: 2px;
  box-shadow:
    0 1px 3px 0 rgba(0, 0, 0, 0.1),
    0 1px 2px 0 rgba(0, 0, 0, 0.06);
}

/* app failed component */

.app-failed-container {
  padding: 5rem;
  max-width: 72rem;
  margin-left: auto;
  margin-right: auto;
}

.app-failed-container > .app-component-container {
  padding: 1rem;
  margin-bottom: 1rem;
  background-color: #fdf2c7;
  border-radius: 0.5rem;
}

.app-icon {
  margin-right: 0.5rem;
  color: #b36b34;
  width: 1.25rem;
  height: 1.25rem;
}

.app-content {
  display: flex;
  align-items: center;
}

.app-content > h3 {
  color: #b36b34;
  font-size: 1.125rem;
  line-height: 1.75rem;
  font-weight: 500;
}

.app-text {
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  color: #b36b34;
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.secondary-app-container {
  display: flex;
}

.secondary-app-container > button {
  display: inline-flex;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  margin-right: 0.5rem;
  background-color: #b36b34;
  color: #ffffff;
  font-size: 0.75rem;
  line-height: 1rem;
  font-weight: 500;
  text-align: center;
  align-items: center;
  border-radius: 0.5rem;
  cursor: pointer;
}

.app-learn-more {
  margin-right: 0.5rem;
  margin-left: -0.125rem;
  width: 1rem;
  height: 1rem;
}

.ui-location {
  list-style: circle;
  padding: 0 40px;
  margin-top: -20px;
}

.ui-location li {
  text-align: left;
}

/* fullPage styles */

.full-page {
  box-sizing: border-box;
  display: flex;
  width: 98vw;
  height: 97vh;
  justify-content: center;
  align-items: center;
}

.full-page-container {
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.full-page-graphics {
  display: flex;
  justify-content: center;
  height: 65%;
}

.full-page-app-component > p {
  padding: 0px !important;
}

.full-page-app-component > h4 {
  line-height: 35% !important;
}

/* field-modifier styles */

.field-modifier {
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px dashed gray;
  height: 8.3rem;
  border-radius: 6px;
}

.field-modifier-component {
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.field-modifier-icon {
  display: flex;
  justify-content: center;
  margin: 0.75rem;
}

.field-modifier-icon > img {
  height: 1rem;
}

.field-modifier-component > h4 {
  margin-block-start: unset;
  margin-block-end: 0.25rem;
  font-size: 12px;
}

.field-modifier-component > p {
  margin-block-start: unset;
  margin-block-end: 0.25rem;
  font-size: 10px;
}

.field-modifier-component > a {
  font-size: 10px;
  line-height: unset;
}

/* 
/* view-raw-config-button *
.view-raw-config-button {
  background-color: rgb(212, 208, 208);
  border: none;
  width: 18px;
  height: 19px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-left: 10px;
}

.view-raw-config-button span {
  color: black;
  font-size: 10px;
  font-weight: 900;
}


/* MODAL OVERLAY  */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.17);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 600px;
  max-height: 80%;
  overflow: auto;
}

.modal-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.modal-header h2 {
  margin: 0;
}

.modal-header button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: #090909;
}

.json-content {
  background-color: rgb(18, 18, 18);
  color: white;
  padding: 10px;
  /* border-radius: 4px; */
  font-family: monospace;
  display: flex;
  justify-content: center;
  align-items: left;
  white-space: pre;
  text-align: left;
}
